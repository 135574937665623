import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const Footer = () => {
  return (
    <div>
      <BottomNavigation style={{marginTop: "2.5rem"}}>
        <a target="_blank" href="https://www.facebook.com/Inside-Out-Holistic-Health-Beauty-101801295818936/">
          <BottomNavigationAction label="Facebook" icon={<FacebookIcon style={{fill: "#3b5998"}}/>}/>
        </a>
        <a target="_blank" href="http://www.instagram.com/insideoutholistic/">
          <BottomNavigationAction label="Instagram" icon={<InstagramIcon style={{fill: "#C13584"}}/>}/>
        </a>
        <a target="_blank" href="http://youtube.com">
          <BottomNavigationAction label="YouTube" icon={<YouTubeIcon style={{fill: "#C4302B"}}/>}/>
        </a>
        <a href="mailto:Info@InsideOutHolistic.com">
          <BottomNavigationAction label="Email" icon={<EmailIcon style={{fill: "#449A76"}}/>}/>
        </a>
      </BottomNavigation>
      <p style={{textAlign: "center", color: "#2e1667", marginBottom: "2.5rem"}}>
        &copy; 2022 Inside Out Holistic Health & Beauty
        </p>
    </div>
  
  )
};

export default Footer;
