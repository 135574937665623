import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";


const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1667",
    },
    secondary: {
      main: "#357DDE",
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
    ],
    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    width: "65%",
    margin: "auto",
    textAlign: "center",
  },
  bigSpace: {
    marginTop: "5rem",
  },
  littleSpace: {
    marginTop: "2.5rem",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

/**
 * A simple placeholder component for unavailable routes.
 * @param props {Object} - No expected props for this component.
 * @returns {JSX.Element}
 * @constructor
 */
const NotFoundPage = props => {
  const classes = styles();
  const location = useLocation();

  return (
    <div className="About">
        <ThemeProvider theme={theme}>
            <div className={`${classes.wrapper}`}>
                <Typography variant="h4" color="secondary">
                    Page Not Found
                </Typography>
                <Typography variant="h5" className={classes.littleSpace} color="primary">
                    Route "{location.pathname}" not found.
                </Typography>
            </div>
        </ThemeProvider>
    </div>
  );
}

export default NotFoundPage;
