import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomButton from "../components/CustomButton";
import { IMAGES } from "../images/images";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1667",
    },
    secondary: {
      main: "#357DDE",
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
    ],
    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    width: "65%",
    margin: "auto",
    textAlign: "center",
  },
  bigSpace: {
    marginTop: "5rem",
  },
  littleSpace: {
    marginTop: "2.5rem",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const ServiceDescPage = (props) => {
  const classes = styles();

  return (
    <div className="ServiceDescPage">
        <ThemeProvider theme={theme}>
            <div className={`${classes.wrapper}`}>
                <Typography variant="h4" color="secondary">
                    {props.serviceName}
                </Typography>
                {!!props.imageName && <img src={`${IMAGES[props.imageName]}`} width={"60%"} className={classes.littleSpace}/>}
                <Typography variant="h5" className={classes.littleSpace} color="primary">
                    {props.desc}
                </Typography>
                <div className={`${classes.grid} ${classes.littleSpace}`}>
                    <a target="_blank" href="http://insideouthhb.janeapp.com" style={{textDecoration: "none"}}>
                        <CustomButton text={"BOOK NOW"}/>
                    </a>
                </div>
            </div>
        </ThemeProvider>
    </div>
  );
}

export default ServiceDescPage;
