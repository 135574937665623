import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomButton from "../components/CustomButton";
import { IMAGES } from "../images/images";
import {INFERTILITY} from "../constants/path-names";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2e1667",
        },
        secondary: {
            main: "#357DDE",
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
        ],
        h4: {
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '2rem',
        },
        h5: {
            fontWeight: 100,
            lineHeight: '2rem',
        },
    },
});

const styles = makeStyles({
    wrapper: {
        width: "65%",
        margin: "auto",
        textAlign: "center",
    },
    bigSpace: {
        marginTop: "5rem",
    },
    littleSpace: {
        marginTop: "2.5rem",
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    centeredList: {
        display: "-moz-inline-stack"
    },
    orderedList: {
        textAlign: "left",
    }
});

const InfertilityPage = (props) => {
    const classes = styles();

    return (
        <div className="ServiceDescPage">
            <ThemeProvider theme={theme}>
                <div className={`${classes.wrapper}`}>
                    <Typography variant="h4" color="secondary">
                        Fertility
                    </Typography>
                    <img src={`${IMAGES[INFERTILITY.imgName]}`} width={"60%"} className={classes.littleSpace}/>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        How Traditional Chinese Medicine (TCM) Can Promote Fertility
                    </Typography>
                    <Typography variant="subtitle1" className={classes.littleSpace} color="primary">
                        “All I want is the two pink lines.”
                    </Typography>

                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Studies have demonstrated that acupuncture as a treatment for infertility shows great results both in man and
                        women in restoring fertility in the patients, by improving the sperm quality and ovaries’ function and
                        balancing the endocrine system and hormones. The results demonstrated an overall 48% to 60% increase in
                        successful pregnancy for women receiving TCM treatment compared with those not receiving it.<br/><br/>
                        Our TCM treatment <b>supports fertility patients undergoing IVF</b>. <b>We support men and women</b> with acupuncture and
                        Chinese herbal medicines to treat infertility. We offer tailored and holistic approaches to address the root causes of your
                        infertility by carefully understanding you and your body’s needs.<br/><br/>
                        TCM sees the body as a whole – mind, body, and spirit one ecosystem. Anything that is affecting the natural conception
                        is seen as a malfunction or disturbance of this ecosystem. Of course, physical issues of the reproductive system, organs
                        and hormones need to be considered. However, each of these elements is not isolated. They need to orchestrate
                        seamlessly to create the right conditions for pregnancy to occur.<br/><br/>
                        TCM’s holistic approach can provide solutions using <b>acupuncture, herbal medicine, and nutrition</b> to increase fertility.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        Part I: How Can TCM Improve Your Chances of <u>Getting Pregnant</u>?
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        In TCM, the energy balance in the meridians (energy highways) to the reproductive organs is important. For fertility, the
                        three key organ systems involved are Kidney, Liver, and Spleen. By seeking TCM treatments to support these organs and
                        balance the energy, your natural production of hormones will improve. Hence, it will increase your chances of getting
                        pregnant.<br/>
                        Fertility problems are combatted to its roots. Treatment plans have one objective: to enhance your chances to have a
                        natural conception. However, to accomplish this, addressing the causes of infertility is the first step to take.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        What <u>Causes</u> Infertility, according to TCM?
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        The causes of fertility are wide-ranging. It can be due to ovarian, fallopian tubes or uterine factors, such as
                        endometriosis or endocrine problems such as PCOS, various autoimmune issues, or sperm defects. Sometimes, there is
                        no clear explanation, and couples are thrown into a spiral of more tests, more diagnosis, and sometimes more
                        disappointments.<br/>
                        In terms of women’s infertility, the four main organ systems of Kidney, Liver, Spleen, and Heart together with Yin, Yang,
                        Qi, and Blood are mostly likely to be imbalanced. These can be caused by an excess or lack of energy, and TCM for
                        fertility aims to nurse them back to its natural function of distributing vital energies throughout the body, and eventually
                        restore their natural fertility. Here are some of the causes of infertility believed in TCM philosophy:
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Stress
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Staying calm and stress-free is not easy, especially when you are trying to conceive amidst busy work schedules and fast-
                        paced lifestyle. The pressure among females who are already in their 30s to 40s does not help as well. In TCM, stress is
                        closely related to Liver Qi stagnation and presents itself as being irritable, sighing often, tight chest or PMS. It is a
                        biological response to a threat or an inability to respond appropriately to the environment, and it can put the body in a
                        “fight or flight” survival mode. Over-worrying, burn out, over-exercising or lack of sleep is often the source of stress and
                        inhibits the utilisation of progesterone that affects fertility. It increases cortisol hormones which redirect the blood flow
                        to the brains and muscles and away from the reproductive organ.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Irregular diet
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Ovulation may be affected by your diet. Irregular or absent ovulation is one of the common causes of infertility since this
                        is the natural body process that is responsible for the hormonal function, which makes a woman’s egg available for
                        fertilisation every month. With a neglected diet, you will increase your risk for irregular ovulation. To help you get a
                        better understanding of your nutritional needs, TCM nutritional therapy will be part of our practitioner’s fertility plan to
                        address the lack of food that should be distributed to your body. It may include warming your foods more often and
                        chewing them thoroughly to aid in preserving your Spleen’s energy.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Effects from our environment
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        TCM promotes the harmony of mind and body to achieve the optimal condition for natural conception. However, living
                        in an industrialised environment where different types of toxic chemicals are present in the air, it can have detrimental
                        effects on your body and affect your fertility. These chemicals are called endocrine disruptors which can enter your body
                        and mimic hormones. It muddles your natural hormonal pattern, which prevents or completely halting your fertility
                        process. As much as possible, ensure that you are avoiding chemicals in cosmetics and perfumes and plastic food
                        containers to block these endocrine-disrupting chemicals. Practitioner will use TCM herbal medicines to support your
                        Liver detoxification process and advise you on foods that can support your fertility.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Lifestyle habits
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Improving your overall health involves the process of evaluating your lifestyle choices. It means that you have to stop
                        your vice of drinking or smoking and replacing it with more movement in your everyday life. A bit of Qi Gong exercise
                        (Ba Duan Jin) or Yoga exercises could be a step to changing the energy of your body. Listening to your body’s needs will
                        not take a lifetime to practice, and TCM believes that your hormonal imbalances are due to your restless shen (the spirit
                        in your heart) and being under or overly active. To restore this imbalance, your TCM practitioner will recommend the
                        correct dietary practices and exercise routines to raise your fertility quotient.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        How does acupuncture work in helping you get pregnant?
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        After making diagnosis for your current condition, TCM practitioner will lay down a plan on how to re-align your body
                        back to its balanced state with acupuncture. Performing acupuncture for fertility involves inserting needles into your
                        body’s acupoints to release blocked energy that is preventing your body to restore its natural balance. Following this, it
                        will eventually boost the fertility process.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        It clears block energies and restores balance
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        A human body is composed of meridians which are also known as energy highways. Each meridian is connected to an
                        organ and lined with acupuncture points, each with their own specific functions. These points play the role of tonifying
                        your bodily systems, including your reproductive system. TCM believes that the imbalance of these energies disrupts
                        your body’s capability to ovulate and stabilise hormonal fluctuations. The gentle insertion of thin and disposable sterile
                        needles to stimulate the 14 major pathways of your body will help restore that balance and improve your fertility.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        It complements Western fertility treatments
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Herbal remedies and prescriptions are often partnered with repeated acupuncture sessions in TCM clinic. When these
                        natural approaches are harmonised, it strengthens and regulates the hormonal systems of your body. When a needle is
                        inserted, the pressure causes a micro electric current, which causes a release of prostaglandins into the bloodstream. It
                        stimulates the brain that controls hormonal activity. Hence, acupuncture can help stimulate the hormonal system and
                        assist in both women’s and men’s fertility. Thus, engaging with regular acupuncture treatment <b>during natural
                        conception or ART</b> (Assisted Reproductive Technology) can support your chance of conceiving.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        It boosts the efficiency of reproductive organs
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        TCM fertility treatment is like prepping the garden soil. The more nourished and irrigated it is, the higher the chance for
                        the seed to grow. Acupuncture can help to improve the flow of energy and bring the much-needed nutrients to boost
                        the efficiency of reproductive organs. Acupuncture points are mainly located on the lower abdomen, legs, and arms
                        which are closely related to the three main organ systems: Kidney, Liver, and Spleen which play a key role in fertility.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        Beyond conceiving: The benefits of TCM for <u>Pregnant Mums</u>
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        A baby underway requires constant care and attention until birth. In the meantime, your body serves as a vessel to
                        support the growth of the baby. TCM treatments do not stop as soon as you fall pregnant. Instead, it continues to
                        improve the condition of your body as a mum.
                        The goal of an infertility treatment from a TCM perspective is not only about increasing the chance of pregnancy, but
                        also ensuring a healthy body while you are carrying the baby. The benefits of getting TCM acupuncture during your
                        pregnancy are:
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Helps prevent miscarriages
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Especially for women <b>going through IUI or IVF</b>, acupuncture is safe and recommended to do until the end of the first
                        trimester to stabilise the pregnancy.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Cope with morning sickness
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Many mums-to-be experience morning sickness, which is an unpleasant side effect of pregnancy. Contrary to what its
                        name suggests, morning sickness can happen at any time of the day. Symptoms may vary, but common symptoms
                        include loss of appetite, bouts of nausea, and vomiting. You can trust that consistent acupuncture can help to alleviate
                        the symptoms.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Alleviates body aches
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Acupuncture can help with aches and pain. Treatment will be given with care to avoid any acupuncture points that are
                        contraindicated for pregnancy.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Support pregnancy fatigue
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Fatigue is a common symptom amongst pregnant mums. It is because your body is adjusting to a load of extra energy
                        dedicated to nourishing your baby, developing the placenta, and adapting to hormonal changes.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        Part II: How TCM can work in tandem during IVF or IUI treatments
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        In-Vitro Fertilisation (IVF) and Intrauterine Insemination (IUI) are two prominent methods of Assisted Reproductive
                        Therapy (ART) that help couples with their fertility problems. Both procedures are effective and have had numerous
                        successful cases from couples all over the world.<br/>
                        In IUI treatment, it aims in giving the sperm a strong chance to reach the fallopian tubes to increase the odds of
                        fertilisation whereas, in IVF, sperm and eggs are fertilized under the hands of an embryologist before being transferred
                        into the uterus. Both have the same end goal, and both can be supported by TCM treatments.<br/>
                        <b>TCM has been widely used to support IVF since 2002</b> when a team of German researchers first reported an increase in
                        clinical pregnancy rates when acupuncture was done during IVF. Results showed that pregnancies assisted with
                        acupuncture were up at 42.5%, versus 26.3% in the control group who were not. Thus, having the same goal as western
                        medicine, they can go hand in hand to achieve improved women’s health and energy flow to increase fertility.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        TCM to Support IVF
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        One cycle of an IVF procedure can take a few weeks to a few months, depending on your case. It involves ovarian
                        stimulation, egg retrieval, sperm retrieval, fertilisation, and embryo transfer. When it comes to IVF, we recommend you
                        start prepping the body at least three months before. It is because it takes 90 days for egg recruitment, and it gives
                        ample time to get your body ready for IVF. However, if you have already started on drug stimulations, your TCM
                        practitioner will schedule your acupuncture treatments in line with your IVF procedure schedule.  Regular acupuncture
                        of twice a week is recommended. The last session usually ends 10 to 12 days after the embryo transfer is completed.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        TCM to Support IUI
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Sperm quality is improved by IUI’s washing method of separating the semen from the seminal fluid. It is done when a
                        man’s sperm count is low or has decreased sperm motility or morphology. It is also an option for unexplained infertility,
                        a hostile cervical condition, a past cervical scar tissue that prevents sperm from entering the uterus, and ejaculation
                        dysfunction. It has a success rate of 10-20% per cycle, but it can go up depending on the <b>sperm and egg quality</b>. With
                        TCM’s acupuncture and herbs, it may increase the chance for successful implantation. Similar to IVF, it is recommended
                        to start TCM treatments three months before priming the body and ensure optimal condition before IUI. Take this time
                        to nourish your body by following a clean diet with organic fruits, nuts, vegetables, probiotic supplements, and high-
                        fibre foods.<br/>
                        A woman’s reproductive health should be in harmony so that there is a higher chance for them to be fertile and bear a
                        child. However, this harmony is disrupted by stress and anxiety that can contribute to infertility and risk of miscarriage.
                        To counter it, TCM appeals that getting acupuncture can boost a woman’s health to achieve this harmony.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        What are the <u>Effects of the Acupuncture</u> Sessions at Different Stages of IVF/IUI treatment?
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        <b>BEFORE IVF/IUI treatment</b>:
                        <ul className={`${classes.centeredList}`}>
                            <li>Calms the patient and relaxes the uterus.</li>
                            <li>Primes the body to ensure optimal condition during IUI/IVF treatment.</li>
                        </ul>
                        <b>DURING IVF/IUI treatment</b>: (during follicle stimulation phase)
                        <ul className={`${classes.centeredList}`}>
                            <li>Strengthens the Spleen and Kidney to enhance their response to the Follicle Stimulation Hormones (FSH).</li>
                            <li>Supports the Liver to improve blood flow to the ovaries and uterus, thereby improving the quality of follicles and
                                thickening the endometrial lining.</li>
                        </ul>
                        <b>AFTER IUI cycle or IVF embryo transfer</b>:
                        <ul className={`${classes.centeredList}`}>
                            <li>Improves circulation and reduces stress.</li>
                            <li>Improves blood circulation to the uterus, thereby maintaining implantation and nourishment of embryos for a
                                healthy and successful pregnancy.</li>
                        </ul>
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Foods to Prioritise for fertility
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Chinese tradition recognises food as our main source of energy and each food has different energetic qualities. For
                        example, hot and spicy foods are heaty and more Yang whereas bitter and raw food is cooling and more Yin. For fertility,
                        TCM generally advocates <b>cooked and warming food</b>. It is to ensure that the digestive fire in our Spleen and Stomach is
                        strong and can digest the food efficiently. In this way, nutrients can be absorbed to nourish the whole body.<br/>
                        It is also important to nourish the body with whole meals that have plenty of vegetables and fruits. Eat organic and
                        hormone-free meats whenever possible. Supplement with a high bioavailability multivitamin and mineral complex to
                        support fertility. Specific food such as Chinese red dates, walnuts, and maca (Peruvian Ginseng) is also known to nourish
                        the Qi and Blood.
                    </Typography>

                    <Typography variant="h6" className={classes.littleSpace} color="primary">
                        Work with a TCM Practitioner
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        In our TCM clinics, practitioner recognises your concern about eating the right types of food to boost your fertility and is
                        equipped with knowledge of nutrition that impacts your reproductive health to further your fertility process. Thus,
                        working together with them can help you create an internal environment where your Qi and blood can flow through
                        your reproductive organs without hindrances.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        Part III: How Can a Traditional Chinese Medicine Help with Male fertility?
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        When trying to conceive, it takes two to tango. As we age, both men and women’s reproductive systems start to decline.
                        Number and quality of the eggs and sperms decrease with our natural ageing process. The long-time perception that the
                        woman is usually the infertile partner when couples fail to conceive has shifted.<br/>
                        Your relationship with a TCM practitioner begins in an in-depth consultation, your medical history, lifestyle habits, and
                        diet. Taking the time getting to know you and your partner, and closely create a bond around your goals on making a
                        baby. In your treatment plan, which may comprise acupuncture sessions and/or Chinese herbal medicine prescriptions,
                        you will see it tailored according to your health history and current condition.<br/>
                        Although success rates are not set in stone, some research has shown that acupuncture, in conjunction with other
                        fertility treatments like Chinese herbal medication, <b>increases success rates by 60%</b>.<br/><br/>

                        Improve your overall health with our acupuncture and herbs to restore fertility and increase the chances of conceiving
                        naturally or support your procedure of any Western ART!<br/><br/>

                        Get the key to fertility with our TCM treatments. Book a <b>FREE 15-minute consultation</b> if you have any questions!
                    </Typography>

                    <div className={`${classes.grid} ${classes.littleSpace}`}>
                        <a target="_blank" href="http://insideouthhb.janeapp.com" style={{textDecoration: "none"}}>
                            <CustomButton text={"BOOK NOW"}/>
                        </a>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default InfertilityPage;
