import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomButton from "../components/CustomButton";
import { IMAGES } from "../images/images";
import {COMPRESSION_STOCKINGS} from "../constants/path-names";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2e1667",
        },
        secondary: {
            main: "#357DDE",
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
        ],
        h4: {
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '2rem',
        },
        h5: {
            fontWeight: 100,
            lineHeight: '2rem',
        },
    },
});

const styles = makeStyles({
    wrapper: {
        width: "65%",
        margin: "auto",
        textAlign: "center",
    },
    bigSpace: {
        marginTop: "5rem",
    },
    littleSpace: {
        marginTop: "2.5rem",
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    centeredList: {
        listStylePosition: "inside",
    },
   orderedList: {
        textAlign: "left",
    }
});

const CompressionStockingsPage = (props) => {
    const classes = styles();

    return (
        <div className="ServiceDescPage">
            <ThemeProvider theme={theme}>
                <div className={`${classes.wrapper}`}>
                    <Typography variant="h4" color="secondary">
                        Compression Stockings
                    </Typography>
                    <img src={`${IMAGES[COMPRESSION_STOCKINGS.imgName]}`} width={"60%"} className={classes.littleSpace}/>
                    <Typography variant="h5" className={classes.littleSpace} color="primary">
                        Compression stockings are a special type of elastic stocking that work to <b>support healthy blood circulation</b> in the leg and prevent a range of issues such as:
                        <ul className={`${classes.centeredList}`}>
                            <li><b>Tired &amp; fatigue easily</b></li>
                            <li><b>Restless legs</b></li>
                            <li><b>Night cramps</b></li>
                            <li><b>Pain or fatigue in legs</b></li>
                            <li><b>Swollen feet and ankles</b></li>
                            <li><b>Heavy or aching limbs</b></li>
                        </ul>
                        Compression stockings not only offer many health benefits but also improve comfort, <b>athletic performance</b>, and prevent serious medical conditions. Whether it’s following a varicose vein treatment, or to relieve pressure in your legs.<br/>
                        It works by applying a type of elastic device (like compression stockings), to exert a controlled pressure on them. By compression the limbs or other body regions, the medical compression device squeezes the vein walls together, thereby <b>improving overall circulation and supporting blood flow back towards the heart</b>.<br/>
                        In addition, it helps to reduce <b>swelling</b> and formation of <b>edema</b> by reducing the capillary leakage into the tissue and supports the <b>lymphatic drainage</b>. It can be applied in different pressure degrees, forms, and styles and materials based on the symptoms and needs of the individual.  Garments such as stockings, leggings, shorts, tights, pantyhose, arm sleeves, gloves; wraps, and bandages are available.<br/>

                        <Typography variant="h6" className={classes.littleSpace} color="primary">
                            HOW TO ORDER?
                        </Typography>
                        <ol className={`${classes.centeredList} ${classes.orderedList}`}>
                            <li><b>INFO</b>: Book a FREE 15 min Consultation (phone), or check out our <a target="_blank" href={"https://fb.watch/hmqWLzrBV0/"}>powerpoint presentation</a>.</li>
                            <li><b>APPOINTMENT</b>: Click "Book" to make an appointment, then fill out the online questionnaire to help me get your correct size & place an order. Note: Booking the appointment is just to give you access to fill out the form. The appointment will be automatically cancelled afterwards.</li>
                            <li><b>PAYMENT</b>: A NON-refundable payment will follow after finishing this questionnaire.</li>
                        </ol>
                        <br/>
                        For more information about the compression stocking styles available, click <a target={"_blank"} href={"https://www.sigvaris.com/en-ca/catalog"}>HERE</a> to view the catalogue.
                        <br/>
                    </Typography>
                    <div className={`${classes.grid} ${classes.littleSpace}`}>
                        <a target="_blank" href="http://insideouthhb.janeapp.com" style={{textDecoration: "none"}}>
                            <CustomButton text={"BOOK NOW"}/>
                        </a>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default CompressionStockingsPage;
