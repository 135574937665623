import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import {ALL_PATHS} from "./constants/path-names";
import AboutPage from "./pages/AboutPage";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/NotFoundPage";
import ServiceDescPage from "./pages/ServiceDescPage";
import TestimonialPage from "./pages/TestimonialPage";
import LocationPage from "./pages/LocationPage";
import CompressionStockingsPage from "./pages/CompressionStockingsPage";
import InfertilityPage from "./pages/InfertilityPage";
import AllergyPage from "./pages/AllergyPage";


class App extends React.Component {
  render() {
    return (
      <Container fluid id="page-container">
        <Row style={{margin: 0}}>
          <Col>
            <Switch>
              <Route exact path={"/"} component={LandingPage}/>
              <Route path={ALL_PATHS.ABOUT.value} component={AboutPage}/>
              <Route path={ALL_PATHS.ACUPUNCTURE.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.ACUPUNCTURE.name}
                  imageName={ALL_PATHS.ACUPUNCTURE.imgName}
                  desc={ALL_PATHS.ACUPUNCTURE.desc} 
                />
              </Route>
              <Route path={ALL_PATHS.CUPPING.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.CUPPING.name} 
                  imageName={ALL_PATHS.CUPPING.imgName} 
                  desc={ALL_PATHS.CUPPING.desc}
                />
              </Route>
              <Route path={ALL_PATHS.EAR_SEED.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.EAR_SEED.name} 
                  imageName={ALL_PATHS.EAR_SEED.imgName} 
                  desc={ALL_PATHS.EAR_SEED.desc}
                />
              </Route>
              <Route path={ALL_PATHS.FACIAL_REJUV.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.FACIAL_REJUV.name} 
                  imageName={ALL_PATHS.FACIAL_REJUV.imgName} 
                  desc={ALL_PATHS.FACIAL_REJUV.desc}
                />
              </Route>
              <Route path={ALL_PATHS.HERBAL_MASK.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.HERBAL_MASK.name} 
                  imageName={ALL_PATHS.HERBAL_MASK.imgName} 
                  desc={ALL_PATHS.HERBAL_MASK.desc}
                />
              </Route>
              <Route path={ALL_PATHS.MICRONEEDLE.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.MICRONEEDLE.name} 
                  imageName={ALL_PATHS.MICRONEEDLE.imgName} 
                  desc={ALL_PATHS.MICRONEEDLE.desc}
                />
              </Route>
              <Route path={ALL_PATHS.HERBAL_CONSULT.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.HERBAL_CONSULT.name} 
                  imageName={ALL_PATHS.HERBAL_CONSULT.imgName} 
                  desc={ALL_PATHS.HERBAL_CONSULT.desc}
                />
              </Route>
              <Route path={ALL_PATHS.REIKI.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.REIKI.name} 
                  imageName={ALL_PATHS.REIKI.imgName} 
                  desc={ALL_PATHS.REIKI.desc}
                />
              </Route>
              <Route path={ALL_PATHS.ANGEL_CARD.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.ANGEL_CARD.name} 
                  imageName={ALL_PATHS.ANGEL_CARD.imgName} 
                  desc={ALL_PATHS.ANGEL_CARD.desc}
                />
              </Route>
              <Route path={ALL_PATHS.COMPRESSION_STOCKINGS.value}>
                <CompressionStockingsPage/>
              </Route>
              <Route path={ALL_PATHS.FREE_CONSULT.value}>
                <ServiceDescPage
                  serviceName={ALL_PATHS.FREE_CONSULT.name} 
                  imageName={ALL_PATHS.FREE_CONSULT.imgName} 
                  desc={ALL_PATHS.FREE_CONSULT.desc}
                />
              </Route>
              <Route path={ALL_PATHS.INFERTILITY.value}>
                <InfertilityPage/>
              </Route>
              <Route path={ALL_PATHS.ALLERGY.value}>
                <AllergyPage/>
              </Route>
              <Route path={ALL_PATHS.TESTIMONIAL.value} component={TestimonialPage}/>
              <Route path={ALL_PATHS.LOCATION.value} component={LocationPage}/>
              <Route component={NotFoundPage}/>
            </Switch>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;
