import { Link, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { NavLink } from "react-router-dom";
import { SERVICES_ITEMS } from "../constants/menuItems";
import { ALL_PATHS } from "../constants/path-names";
import logo from "../logo.png";
import logoMobile from "../logoMobile.png";

const styles = makeStyles({
    bar: {
        paddingTop: "0.25rem",
        backgroundColor: "#fff",
        ["@media (max-width: 780px)"]: {
            flexDirection: "column",
        },
    },
    logo: {
        width: "15%",
        ["@media (max-width: 780px)"]: {
            display: "none",
        },
    },
    logoMobile: {
        width: "100%",
        display: "none",
        ["@media (max-width: 780px)"]: {
            display: "inline-block",
        },
    },
    menuItem: {
        textAlign: "center",
        color: "#2e1667",
        cursor: "pointer",
        flexGrow: 1,
        "&:hover": {
            color: "#357DDE",
        },
        ["@media (max-width: 780px)"]: {
            paddingBottom: "1rem",
        },
    },
});

const NavBar = () => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Toolbar position="sticky" color="rgba(0, 0, 0, 0.87)" className={classes.bar}>
            <img src={logoMobile} className={classes.logoMobile}/>

            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={NavLink}
                to={"/"}
                style={{textDecoration: "none"}}
            >
                Home
            </Typography>

            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={NavLink}
                to={ALL_PATHS.ABOUT.value}
                style={{textDecoration: "none"}}
            >
                About
            </Typography>

            <Typography 
                variant="h6" 
                className={classes.menuItem}
                onClick={handleOpenMenu}
                aria-controls="menu"
            >
                Services
            </Typography>
            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={NavLink}
                to={ALL_PATHS.TESTIMONIAL.value}
                style={{textDecoration: "none"}}
            >
                Testimonial
            </Typography>

            <img src={logo} className={classes.logo}/>
            
            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={NavLink}
                to={ALL_PATHS.LOCATION.value}
                style={{textDecoration: "none"}}
            >
                Location
            </Typography>
            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={Link}
                target="_blank"
                href="http://insideouthhb.janeapp.com"
                underline="none"
            >
                Book
            </Typography>
            <Typography 
                variant="h6" 
                className={classes.menuItem}
                component={Link}
                target="_blank"
                href="https://squareup.com/gift/MLK9822PB5JGR/order"
                underline="none"
            >
                Gift
            </Typography>
        </Toolbar>
        <Menu 
          id="menu" 
          anchorEl={anchorEl} 
          open={Boolean(anchorEl)} 
          onClose={handleCloseMenu}
        >
          {SERVICES_ITEMS.map(item => (
            <MenuItem onClick={handleCloseMenu}>
              <Typography
                className={classes.menuItem}
                component={NavLink}
                to={item.path}
                style={{textDecoration: "none"}}
              >
                {item.title}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
};

export default NavBar;