import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomButton from "../components/CustomButton";
import { IMAGES } from "../images/images";
import {ALLERGY} from "../constants/path-names";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2e1667",
        },
        secondary: {
            main: "#357DDE",
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
        ],
        h4: {
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '2rem',
        },
        h5: {
            fontWeight: 100,
            lineHeight: '2rem',
        },
    },
});

const styles = makeStyles({
    wrapper: {
        width: "65%",
        margin: "auto",
        textAlign: "center",
    },
    bigSpace: {
        marginTop: "5rem",
    },
    littleSpace: {
        marginTop: "2.5rem",
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    centeredList: {
        display: "-moz-inline-stack"
    },
    orderedList: {
        textAlign: "left",
    }
});

const AllergyPage = (props) => {
    const classes = styles();

    return (
        <div className="ServiceDescPage">
            <ThemeProvider theme={theme}>
                <div className={`${classes.wrapper}`}>
                    <Typography variant="h4" color="secondary">
                        Allergy Elimination with NAET Techniques
                    </Typography>
                    <img src={`${IMAGES[ALLERGY.imgName]}`} width={"60%"} className={classes.littleSpace}/>

                    <Typography variant="body1" color="primary">
                        NAET uses a combination of the <i>non-invasive, drug-free, natural</i> procedures from Western and Eastern healing practices that help to eliminate sensitivities.
                        It is a specific treatment procedure using the principles based on Chinese Medicine Acupuncture points, Chiropractic theory and Applied Kinesiology.<br/>

                        NAET was discovered by Dr. Devi S. Nambudripad in 1983.
                        It uses neuromuscular sensitivity testing (NST) to confirm the presence of allergic reactivity.
                        Once identified, acupressure and/or acupuncture procedures will be used to eliminate the sensitivity by balancing the body in the presence of the allergen.<br/>

                        The treatment is geared to re-program the brain's negative responses towards the allergen(s) to a positive response whenever these substances are contacted in the future.
                        NAET is based on the Chinese medicine principle that "no disease is possible when your body is in perfect balance."
                        NAET offers an energy-medicine solution to allergic conditions by balancing the body and mind.<br/><br/>

                        Below is the link to a video which explains how NAET works:<br/>
                        <a href="https://www.youtube.com/watch?v=jhZMdXP7O8k">NAET Patient Education Video</a><br/><br/>

                        One allergen is treated at a time. If you are not severely immune deficient, you may need just one treatment to desensitize one allergen.
                        A person with a mild to moderate number of allergies may take about 15-20 office visits to desensitize 15-20 food and environmental allergens.<br/><br/>

                        Basic essential nutrients are treated during the first few visits.
                        Chemicals, environmental allergens, vaccinations, immunizations, etc. are treated after completing about fifteen basic essential nutrients.
                        NAET can successfully alleviate adverse reactions to egg, milk, peanuts, penicillin, aspirin, mushrooms, shellfish, latex, grass, ragweed, flowers, perfume, animal dander, animal epithelial, make-up, chemicals, cigarette smoke, pathogens, heat, cold, other environmental agents if treated appropriately following the correct NAET treatment protocol.
                        It may take several office visits to desensitize a severe allergen.<br/>
                        * <i>Allergy with severe or anaphylactic reaction(s) are not done at our office at this time</i><br/><br/>

                        NAET alleviate allergies of all types by using a blend of selective energy balancing, testing and treatment procedures from the following disciplines of medicine:
                        <ol className={`${classes.centeredList}`}>
                            <li>Chinese Medicine Acupuncture/ Acupressure</li>
                            <li>Allopathy</li>
                            <li>Chiropractic</li>
                            <li>Nutrition</li>
                            <li>Kinesiology</li>
                        </ol>

                        The following paragraphs explain how each discipline of medicine helps to alleviate allergy:
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="secondary">
                        Allopathy &amp; Western Sciences
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Knowledge of the brain, cranial nerves, spinal nerves and autonomic nervous system from Western medicine enlightens us about the body’s efficient multilevel communication network.
                        Through this network of nerves, vital energy circulates through the body carrying negative and positive messages from the brain to each and every cell in the body and back to the brain.
                        Knowledge of the nervous system, its origin, travel pathway, the organs and tissues that will benefit from its nerve energy supplies (target organs and tissues) and its destination, helps us to understand the energy distribution of particular spinal nerves that emerge from the 31 pairs of spinal nerve roots.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="secondary">
                        Kinesiology
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Kinesiology is the art and science of movement of the human body. Kinesiology is used in NAET to compare the strength and weakness of any muscle (also known as neuromuscular sensitivity testing (NST) of the body in the presence and absence of any substance.
                        A measured weakness in the presence of a substance is due to the effect of an allergy to the item the person is touching.
                        This simple method can be used to detect your allergens.
                        This finding is verified via appropriate laboratory testing. One of them is known as IgE (Immunoglobulin-E).
                        These tests detect the presence and levels of antibodies in the blood towards the particular allergen tested.
                        These tests are mandatory before beginning the desensitization treatment using acupuncture/acupressure modalities.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="secondary">
                        Chiropractic
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Chiropractic principles help us to detect the nerve energy blockage in a specific nerve energy pathway by detecting and isolating the exact nerve root being pinched.
                        The exact vertebral level in relation to the pinched spinal nerve root helps us to trace the travel route, the destination and the target organs of that particular energy pathway.
                        D. D. Palmer, considered the “Father of Chiropractic,” said, “too much or too little energy is disease.”
                        A pinched nerve can cause disturbance in the energy flow.
                        Chiropractic medicine postulates that a pinched nerve or any such disturbance in the energy flow can cause disease, revealing the importance of maintaining an uninterrupted flow of nerve energy.
                        A pinched nerve or an obstruction in the energy flow is often the result of an allergy.
                        Spinal manipulation at the specific vertebral level of the pinched nerve, can relieve the obstruction of the energy flow and help the body to arrive at a state of homeostasis.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="secondary">
                        Chinese Medicine and Acupuncture
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Yin-Yang theory from Oriental medical principles teaches the importance of maintaining homeostasis in the body.
                        According to Chinese medical principles, “when the body is in perfect balance, no disease is possible.”
                        Any disturbance in the homeostasis can cause disease.
                        Any allergen capable of producing a weakening muscular effect in the body can cause disturbance in homeostasis.
                        Hence, diseases can be prevented and cured by maintaining homeostasis.
                        According to acupuncture theory, acupuncture and/or Acupressure at certain acupuncture points is capable of bringing the body to a state of homeostasis by removing the energy blockages from the energy pathways known as meridians.
                        When the blockages are removed, energy can flow freely through the energy meridians, thus bringing the body in perfect balance.
                    </Typography>

                    <Typography variant="h5" className={classes.littleSpace} color="secondary">
                        Nutrition
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        You are what you eat! The secret to good health is achieved through the right nutrition. What is right nutrition?<br/>
                        When you can eat nutritious foods without discomfort and assimilate their nutrients, that food is said to be the right food.
                        When you get indigestion, bloating, or other digestive troubles upon eating the food, that food is not helping you function normally.
                        This is due to an allergy to the food. Different foods react differently to different people. What is food for some may be poison for others.
                        You've probably heard the expression, “one man’s meat is another man’s poison.”<br/>
                        So it is very important to clear the allergy of the nutrients. People with allergies can tolerate food that is low in nutrition better than nutritious food.
                        Therefore, upon clearing the allergy, they should try to eat more wholesome, nutritious foods, and should avoid refined, bleached food that is devoid of nutrients.<br/>
                        Many people who are feeling poorly due to undiagnosed food allergies, may take vitamins or other supplements to increase their vitality after they get treated for the specific allergy.
                        If they happen to be allergic to the nutritional supplements that they are taking, this can actually make them feel worse.
                        Only after clearing those allergies, can their bodies properly assimilate them.
                        So nutritional assessment should be done periodically and if needed, appropriate supplements should be taken to receive faster results.
                    </Typography>

                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        <b>More videos related to other conditions and NAET</b>:
                        <ul className={`${classes.centeredList}`}>
                            <li><a href="https://youtu.be/ekSCcI6H7ZE">NAET and Autism</a></li>
                            <li><a href="https://youtu.be/r23-efLbsp8">NAET helped A.D.D., headaches, acne, and sinusitis</a></li>
                            <li><a href="https://youtu.be/N51w7PXEUOA">Medication Induced Hives Eliminated with NAET</a></li>
                            <li><a href="https://youtu.be/X8aPFO885Nc">NAET and Infertility</a></li>
                        </ul>
                        <b>Disclaimer</b>: NAET does NOT claim to cure allergies of food, chemical and environmental sensitivities.
                    </Typography>

                    <div className={`${classes.grid} ${classes.littleSpace}`}>
                        <a target="_blank" href="http://insideouthhb.janeapp.com" style={{textDecoration: "none"}}>
                            <CustomButton text={"BOOK NOW"}/>
                        </a>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default AllergyPage;
