import {ALL_PATHS} from "./path-names"

export const MENU_ITEMS = [
    {
        title: "About"
    },
    {
        title: "Services",
    },
    {
        title: "Testimonial"
    },
    {
        title: "Book"
    },
    {
        title: "Gift"
    },
];

export const SERVICES_ITEMS = [
    {
        title: ALL_PATHS.ACUPUNCTURE.name,
        path: ALL_PATHS.ACUPUNCTURE.value,
    },
    {
        title: ALL_PATHS.ALLERGY.name,
        path: ALL_PATHS.ALLERGY.value,
    },
    {
        title: ALL_PATHS.CUPPING.name,
        path: ALL_PATHS.CUPPING.value,
    },
    {
        title: ALL_PATHS.EAR_SEED.name,
        path: ALL_PATHS.EAR_SEED.value,
    },
    {
        title: ALL_PATHS.FACIAL_REJUV.name,
        path: ALL_PATHS.FACIAL_REJUV.value,
    },
    {
        title: ALL_PATHS.HERBAL_MASK.name,
        path: ALL_PATHS.HERBAL_MASK.value,
    },
    {
        title: ALL_PATHS.INFERTILITY.name,
        path: ALL_PATHS.INFERTILITY.value,
    },
    {
        title: ALL_PATHS.MICRONEEDLE.name,
        path: ALL_PATHS.MICRONEEDLE.value,
    },
    {
        title: ALL_PATHS.HERBAL_CONSULT.name,
        path: ALL_PATHS.HERBAL_CONSULT.value,
    },
    {
        title: ALL_PATHS.REIKI.name,
        path: ALL_PATHS.REIKI.value,
    },
    {
        title: ALL_PATHS.ANGEL_CARD.name,
        path: ALL_PATHS.ANGEL_CARD.value,
    },
    {
        title: ALL_PATHS.COMPRESSION_STOCKINGS.name,
        path: ALL_PATHS.COMPRESSION_STOCKINGS.value,
    },
    {
         title: ALL_PATHS.FREE_CONSULT.name,
        path: ALL_PATHS.FREE_CONSULT.value,
    },
];