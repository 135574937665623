import {Typography} from "@material-ui/core";
import {createTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {Col, Row} from "reactstrap";
import {IMAGES} from "../images/images";


const theme = createTheme({
    palette: {
        primary: {
            main: "#2e1667",
        },
        secondary: {
            main: "#357DDE",
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
        ],
        h4: {
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '2rem',
        },
        h5: {
            fontWeight: 100,
            lineHeight: '2rem',
        },
    },
});

const styles = makeStyles({
    wrapper: {
        width: "65%",
        margin: "auto",
        textAlign: "center",
    },
    gridWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 5rem 0 5rem",
    },
    bigSpace: {
        marginTop: "5rem",
    },
    littleSpace: {
        marginTop: "1rem",
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    diseaseList: {
        columns: 3,
        listStyleType: "none",
        color: "#2F6AB9",
        ["@media (max-width: 780px)"]: {
            columns: 2,
            paddingLeft: "0",
        },
    },
    centeredList: {
        color: "#2F6AB9",
        display: "-moz-inline-stack",
        listStyleType: "none"
    },
});

const AboutPage = () => {
    const classes = styles();
    const disease_list_jsx = (
        <ul className={`${classes.centeredList}`}>
            <li>Fertility</li>
            <li>Cervical Ripening</li>
            <li>Pre- &amp; Post-Natal Care</li>
            <li>Radio-Therapy Support</li>
            <li>Chemo-Therapy Support</li>
            <li>Medical Microneedling</li>
            <li>PTSD Vital Alignment</li>
            <li>Facial Rejuvenation</li>
            <li>Menopause</li>
            <li>Anxiety</li>
            <li>Insomnia</li>
            <li>NAET Allergies Elimination, NO needle</li>
            <li>Chronic Fatigue Syndrome</li>
            <li>MVA, Physical injuries</li>
            <li>Fibromyalgia</li>
            <li>Arthritis</li>
            <li>Dry Eyes</li>
            <li>Digestion</li>
            <li>Headaches</li>
            <li>PMS, PCOS</li>
            <li>Post COVID</li>
            <li>Depression</li>
            <li>Eye Diseases</li>
            <li>Endometriosis</li>
            <li>Varicose Veins</li>
            <li>Compression Stockings</li>
        </ul>);


    return (
        <div className="About">
            <ThemeProvider theme={theme}>
                <div className={`${classes.wrapper}`}>
                    <Row className={`${classes.grid}`}>
                        <Col className={`${classes.gridWrapper}`}>
                            <Typography variant="h4" color="secondary">
                                Dr. Florence Kwok
                            </Typography>
                            <Typography variant="h5" className={classes.littleSpace} color="primary">
                                Doctor of Acupuncture
                            </Typography>
                            <Typography variant="h5" color="primary">
                                Herbalist
                            </Typography>
                            <Typography variant="h5" color="primary">
                                Registered Pharmacist, B. Sc. Pharm.
                            </Typography>
                            <Typography variant="h5" color="primary">
                                Additional Prescribing Authority (APA)
                            </Typography>
                        </Col>
                        <Col><img src={`${IMAGES["drKwok"]}`} className={classes.bigSpace} width={"15%"}/></Col>
                    </Row>
                </div>
                <div className={`${classes.wrapper}`}>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Being a licensed pharmacist in Canada since 1989 together with her doctorate of Traditional
                        Chinese Medicine (TCM) and Acupuncture, she is fulfilling her life-long dream of being a
                        well-integrated practitioner of both Western and Eastern medicine.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Florence was a member of the Chinese Medicine &amp; Acupuncture Association of Canada from 2002 to 2017,
                        after completing her studies in Manitoba. In order to follow her heart and foster her passion in life,
                        she moved to Calgary to further her studies in Chinese Medicine.
                        She obtained a doctorate of TCM &amp; Acupuncture, and graduated as the valedictorian of her class.
                        Not only did she receive the Clinical Excellence Award &amp; the Canada Sage Herb Biotechnology scholarship,
                        but also the Citizenship Award &amp; the Shen-Nong Scholarship (national) due to her outstanding community involvement.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        She also has a particular interest in compounding and received Non-Sterile Compounding pharmacist training in
                        2015. Incorporating this compounding skill and knowledge with Chinese medicine will be one of her
                        objectives.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        She continued to advance herself and studied:
                        <ul className={`${classes.centeredList}`}>
                            <li>NAET <b>Allergy</b> Elimination</li>
                            <li><b>Medical Micro-needling</b> (certified)</li>
                            <li><b>Facial Rejuvenation</b> Acupuncture (certified)</li>
                            <li><b>Chemotherapy &amp; Radio-therapy</b> Support</li>
                            <li><b>Pregnancy, Prenatal, Postnatal and Cervical Ripening</b></li>
                            <li><b>The Complete Fertility</b> &amp; TCM Treatments (certified course)</li>
                            <li><b>Orthopedic Sport Medicine</b>, Motor-Points needling (powerful muscle resetting)</li>
                            <li>Post-Traumatic Vital Alignment for <b>PTSD</b> (physical, mental &amp; emotional trauma)</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        She also passed the Pan-Canadian Examinations (of British Columbia) for TCM Herbalists to become a more
                        comprehensive TCM Practitioner.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        She has special interest in treating various diseases and chronic illnesses.
                        Some of the examples include, but are not limited to:
                        {disease_list_jsx}
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        Traditional Chinese medicine (TCM), a system of medicine at least 23 centuries old, aims to prevent diseases or
                        promote healing of diseases by maintaining or restoring yin-yang balance.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        There is a flow of vital energy (called qi) throughout the body via certain pathways (meridians &amp; collaterals). If
                        this is blocked, weak or excessive, disease and other emotional, mental &amp; physical health problems develop.
                        Restoring the flow of qi is considered essential to balance the yin &amp; yang and, in turn, achieving good health &amp;
                        wellness.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        She has had arthritis since she was 17. Nonetheless, she ran her first half-marathon at age 49 after only running
                        for 3 months and planning to finish her first full marathon at age 60.<br/>
                        She keeps her condition well under control with less frequent &amp; less intense flare-ups by Chinese herbal
                        remedies &amp; acupuncture. She has also gone through a long journey of gastrointestinal disorder with food
                        allergies &amp; 6 years of postpartum depression. Her personal health issues are the strongest driving force to
                        motivate her to make a life-changing decision to become a well-integrated TCM practitioner.
                    </Typography>
                    <Typography variant="body1" className={classes.littleSpace} color="primary">
                        It will be truly an honour for her to be a part of your journey to health and well-being!
                    </Typography>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default AboutPage;
